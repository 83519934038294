// Routes
import NotFound from '../components/NotFound'
import Research from '../components/Research'
import Latest from '../components/Latest'
import Photos from '../components/Photos'
import MyCV from '../components/MyCV'
import Projects from '../components/Projects'
import ProjectDetail from '../components/ProjectDetail'
import Contact from '../components/Contact'
import Login from '../components/Login'

import Admin from '../components/Admin'
import ManageNews from '../components/ManageNews'
import ManageLocationHistory from '../components/ManageLocationHistory'

import Site from '../components/Site';
import ManagePublications from '../components/ManagePublications'

const ROUTES = [
  {
    path: "/",
    element: <Site />,
    errorElement: <NotFound status={404} />,
    children: [
      {
        index: true,
        element: <Latest />,
      },
      {
        path: "research",
        element: <Research />,
      },
      {
        path: "photos",
        element: <Photos />,
      },
      {
        path: "projects",
        element: <Projects />,
      },
      {
        path: "project/:project_id",
        element: <ProjectDetail />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "cv",
        element: <MyCV />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "admin",
        element: <Admin />,
        children: [
          {
            index: true,
            path: "news",
            element: <ManageNews />
          },
          { 
            path: "publications",
            element: <ManagePublications />
          },          
          { 
            path: "locations",
            element: <ManageLocationHistory />
          }
        ]
      },
    ]
  },
  
]

export default ROUTES;