import { Link } from '@mui/icons-material';
import { Button, Card, CardActions, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';

import React, { useEffect, useState } from 'react';

const STYLES = {
  root: {
    justifyContent: 'space-around',
  },
  gridList: {
    width: '100%'
  },
  icon: {
    color: "#FFF"
  },
  year: {
    color: "#CCC",
    marginTop: 10
  },
  actions: {
    backgroundColor: "#EFEFEF"
  }
};

const PROJECTS = [
    {
        url: "https://treeoneone.jgordon.io/",
        title: "Tree One One",
        description: "Meet your tallest neighbors",
        image: "/images/projects/spm.png",
        showing: false    
    },
    {
        url: "http://smartmatatu.com/",
        title: "SmartMatatu",
        description: "Tech-enabled driver safety for minibuses in Kenya",
        image: "/images/projects/smartmatatu.png",
        year: 2017,
        showing: false
    },
    {
        url: "https://spm.3ayin.com/",
        title: "Sudan Protest Monitor",
        description: "The Sudan Protest Monitor is a powerful spatial and visual archive, developed in collaboration with Gisa Group and Ayin News, showing a year of constant protest in Sudan",
        image: "/images/projects/spm.png",
        year: 2022,
        showing: true
    },
    {
        url: "/project/adp",
        code: "https://github.com/onejgordon/active-dynamical-prospection",
        title: "Active Dynamical Prospection Agent",
        description: "A computational model of mental simulation underlying sensorimotor control during pathfinding",
        image: "/images/projects/adp.png",
        year: 2021,
        showing: true
    },
    {
        url: "https://github.com/onejgordon/sr-dyna",
        code: "https://github.com/onejgordon/sr-dyna",
        title: "Python Implementation of SR-Dyna",
        description: "A Python/numpy implementation of SR-Dyna from Russek, Momennejad, Botvinick, Gershman and Daw, 2017.",
        image: "/images/projects/srdyna.png",
        year: 2020,
        showing: true
    },
    {
        url: "http://flowdash.co",
        title: "Flow Dashboard",
        description: "Personal data, habit tracking, focus. No longer maintained.",
        image: "/images/projects/flow.png",
        year: 2017,
        showing: true
    },
    {
        url: "http://mindgraph.jgordon.io",
        title: "MindGraph",
        description: "Simple mind map editor / navigator for people who think in graphs",
        image: "/images/projects/mindgraph.png",
        year: 2017,
        showing: true
    },
    {
        url: "https://action-potential.appspot.com",
        deprecated: true,
        title: "Action Potential",
        description: "An experimental, ultra-lightweight decision app for distributed teams",
        image: "/images/projects/actionpotential.png",
        year: 2017,
        showing: true
    },
    {
        url: "http://cloudymemory.shoreeast.net",
        deprecated: true,
        title: "Cloudy Memory",
        description: "Explore day-long slices of your personal data",
        image: "/images/projects/cloudymemory.png",
        year: 2016,
        showing: true
    },
    {
        url: "https://onejgordon.github.io/log10-timeline/",
        title: "Log10 Timeline",
        description: "Generate log-10 scale PDF timelines",
        image: "/images/projects/log10.png",
        year: 2016,
        showing: true
    }
]

export default function Projects() {

    const activeProjects = PROJECTS.filter((prj) => prj.showing)
    return (
        <Container maxWidth="lg">
            <p className="lead">Links to a few projects (both recent and very old).</p>
            <Grid container spacing={2}>
                
              {activeProjects.map((prj) => 
                <Grid item xs={6} key={prj.image}>
                    <Card>
                        <CardMedia
                            sx={{ height: 140 }}
                            image={prj.image}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            { prj.title }
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            { prj.description }
                            </Typography>
                            <Typography variant="body2" style={STYLES.year}>
                                { prj.year }
                            </Typography>
                        </CardContent>
                        <CardActions style={STYLES.actions}>
                            <Button size="small" href={prj.url} disabled={prj.deprecated} endIcon={<Link />}>{ prj.deprecated ? "Link No Longer Active" : "Go to Project" }</Button>
                        </CardActions>
                    </Card>
                </Grid>
              )}
            </Grid>
        </Container>
    );
}
