import React from 'react';
import ThemeLink from './ThemeLink'
import { Container } from '@mui/material';

const STYLE = {
    iframe: {
        width: '100%',
        height: '800px',
        border: '0px'
    },
    cv: {
        display: "block",
        width: "100%",
        minHeight: "1000px",
        border: "0"
    }
}

const CV_LINK = "/documents/GORDON Jeremy.pdf";

export default function MyCV({ theme }) {
    return (
        <Container maxWidth="lg">

            <div>
                <object style={STYLE.cv} data={CV_LINK} type="application/pdf">
                    <embed src={CV_LINK} style={STYLE.cv} type="application/pdf" />
                </object>
                {/* <iframe src="/public/cv.html" style={STYLE.iframe} frameborder="0">
                    <p>It appears your web browser doesn't support iframes.</p>
                </iframe> */}
            </div>

            <small>If your browser hasn't loaded the PDF above, <ThemeLink theme={theme} href={CV_LINK} target="_blank">download it here</ThemeLink>.</small>
        </Container>
    );
}
