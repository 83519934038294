import React, { useEffect, useState } from 'react';
import ThemeLink from './ThemeLink'
import {Paper, List, ListItem, Dialog, IconButton, TextField,
    Button, Toggle, Switch, ListItemButton, ListItemIcon, ListItemText, DialogTitle, DialogContent, DialogActions, FormControlLabel} from '@mui/material';
import Icon from '@mui/material/Icon';
import {clone} from 'lodash'

import { addNews, getLatestNews, updateNews } from '../utils/db_helper';


export default function ManageNews() {
    const [posts, setPosts] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false)
    const [form, setForm] = useState({})
    const [editingId, setEditingId] = useState(null)
    const [creating, setCreating] = useState(false)

    useEffect(() => {
        refreshList()
    }, [])

    const refreshList = () => {
        setPosts([])
        getLatestNews(30, true).then((posts) => {
            console.log(`Got ${posts.length} posts`);
            setPosts(posts)
        })
    }

    const handleChange = (prop, e) => {
        setForm({
            ...form,
            [prop]: e.target.value
        })
    }

    const handleToggleChange = (e) => {
        setForm({
            ...form,
            live: e.target.checked
        })
    }

    const open_dialog = () => {
        setDialogOpen(true)
        setForm({})
    }

    const dismiss_dialog = () => {
        setDialogOpen(false)
        setCreating(false)
        setEditingId(null)
    }

    const edit_post = (doc) => {
        setCreating(false)
        setEditingId(doc.id)
        setForm(clone(doc.data()))
        setDialogOpen(true)
    }

    const start_new = () => {
        setCreating(true)
        open_dialog()
        setEditingId(null)
    }

    const save = () => {
        if (creating) {
            addNews(form).then((post) => {
                refreshList()
                setPosts(posts)
                setDialogOpen(false)
            })
        } else {
            updateNews(editingId, form).then((post) => {
                refreshList()
                setDialogOpen(false)
                setEditingId(null)
            })    
        }
    }

    const render_post = (doc) => {
        let p = doc.data()
        let icon = <Icon className="material-icons">{p.icon || 'link'}</Icon>
        // let delete = <IconButton iconClassName="material-icons">delete</IconButton>
        let style = {}
        if (!p.live) style.opacity = 0.5
        return (
            <ListItemButton onClick={() => edit_post(doc)} key={p.text} style={style}>
                <ListItemIcon>
                    <Icon>{ icon }</Icon>
                </ListItemIcon>
                <ListItemText primary={p.text} secondary={p.date} />
            </ListItemButton>
        )
    }

    return (
        <div>

            <Button variant="contained" onClick={() => start_new()}>New Post</Button>

            <Paper style={{margin: 10}}>
                <List>
                    { posts.map(render_post) }
                </List>
            </Paper>

            <Dialog title="Edit Post"
                    open={dialogOpen}
                    onClose={dismiss_dialog}>
                <DialogTitle>Edit Post</DialogTitle>
                <DialogContent>
                    <TextField name="text" placeholder="Text" fullWidth onChange={(e) => handleChange('text', e)} value={form.text||''} />
                    <TextField name="icon" placeholder="Icon (material icons)" fullWidth onChange={(e) => handleChange('icon', e)} value={form.icon||''} />
                    <TextField name="location" placeholder="Location (e.g. city, state)" fullWidth onChange={(e) => handleChange('location', e)} value={form.location||''} />
                    <TextField name="date" placeholder="Date (YYYY-MM-DD)" fullWidth onChange={(e) => handleChange('date', e)} value={form.date||''} />
                    <TextField name="link" placeholder="Link URL" fullWidth onChange={(e) => handleChange('link', e)} value={form.link||''} />
                    <FormControlLabel control={<Switch name="live" onChange={(e) => handleToggleChange(e)} checked={form.live || false} />} label="Live" />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={save}>Save</Button>
                    <Button onClick={dismiss_dialog}>Cancel</Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}
