import React, { useEffect, useState } from 'react';
import { Avatar, Button, Grid, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import Icon from '@mui/material/Icon';
import util from '../utils/util';
import ThemeLink from './ThemeLink';
import { getLatestNews } from '../utils/db_helper';
import { ExpandMore, More } from '@mui/icons-material';

const STYLES = {
    paper: {
        padding: '10px'
    },
    icon: {
        color: 'rgba(0,0,0,0.5)'
    },
    show_more: {
        textAlign: "center",
        color: "black",
        margin: 20
    }
}

export default function NewsTimeline({ theme }) {
    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(false)
    const [showMore, setShowMore] = useState(false)

    useEffect(() => {
        fetch_recent()
    }, [])

    const fetch_recent = () => {
        getLatestNews(30).then((posts) => {
            setPosts(posts)
            setLoading(false)
        })
        setLoading(true)
    }

    const get_gap_size = (last_date, date) => {
        let gap_days = 0
        if (last_date != null) {
            gap_days = Math.round((last_date - date)/(1000*60*60*24))
        }
        if (gap_days > 31 * 6) return 'gap_very_long'
        else if (gap_days > 31 * 3) return 'gap_long'
        else if (gap_days > 31) return 'gap_medium'
        else if (gap_days > 7) return 'gap_short'
        else return 'gap_tiny'
    }

    const postsToShow = () => {
        let total = showMore ? 30 : 15;
        return posts.slice(0, total);
    }

    let last_date = null
    let loader
    let showMoreButton
    if (!showMore) showMoreButton = (
        <div style={STYLES.show_more}>
            <Button style={{color: "black"}} startIcon={<ExpandMore />} onClick={() => setShowMore(true)}>Show more</Button>
        </div>
    );
    if (loading) loader = <div style={{textAlign: "center"}}><img src='/images/loader.svg' width="20" /></div>
    return (
        <div className="NewsTimeline">
            { loading ? loader : <span className="Line"></span> }
            { postsToShow().map((doc, i) => {
                let post = doc.data()
                let clsDate = "Date"
                let date = new Date(post.date)
                let gap_size = get_gap_size(last_date, date)
                last_date = date
                let days_since = Math.round((new Date()-date)/(1000*60*60*24))
                let recent = days_since <= 60
                let styleDate = {}
                let cls = "row TimelineItem " + gap_size
                if (recent) {
                    cls += " Recent"
                    clsDate += " Recent"
                    // styleDate.color = theme.color
                }
                let icon = post.icon || 'link'
                let secondary = [<span key='date' className={clsDate} style={styleDate}><i className="fa fa-calendar" style={STYLES.icon} /> {util.readableDate(post.date)}</span>]
                if (post.location) secondary.push(<span key='loc'> &nbsp; <i className="fa fa-map-marker-alt" style={STYLES.icon} /> { post.location }</span>)
                if (post.link) secondary.push(<span key='url'> &nbsp; <i className="fa fa-link"/> <ThemeLink theme={theme} href={post.link} target="_blank" className="Link">{post.link}</ThemeLink></span>)
                let fonticon = <Avatar style={{padding: '15px'}}><Icon>{icon}</Icon></Avatar>
                return (
                    <ListItem className={cls} key={doc.id}>
                        <ListItemAvatar>
                            <Avatar>
                                { fonticon }
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={post.text} secondary={secondary} />
                    </ListItem>
                )
            })}

            { showMoreButton }
        </div>
    )
}
