import React from 'react';
import { Link, useLocation } from 'react-router-dom';


function NavBar({ page }) {
  const MENU = [
    { name: "Home", icon: "school", id: "home", to: "/" },
    { name: "Research", icon: "school", id: "research" },
    { name: "Projects", icon: "build", id: "projects" },
    { name: "Photo Stream", icon: "photo_camera", id: "photos" },
    { name: "CV", icon: "work", id: "cv" },
    { name: "Contact", icon: "message", id: "contact" },
  ].reverse()

  const location = useLocation()
  const { pathname } = location;  
  let path = pathname.replace('/','')
  return (
    <div>
      <div className="NavBar">
          { MENU.map((mi, i) => {
            let here = mi.id == path
            let cls = ""
            if (here) cls = "here"
            return <Link to={mi.to || mi.id} className={cls} key={i}>{mi.name}</Link>
          })}
      </div>
      <div className="clearfix"></div>
    </div>
  )
}


export default NavBar