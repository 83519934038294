import React, { useEffect, useState } from 'react';
import ThemeLink from './ThemeLink'
import {Paper, List, ListItem, Dialog, IconButton, TextField,
    Button, Toggle, Switch, ListItemButton, ListItemIcon, ListItemText, DialogTitle, DialogContent, DialogActions, FormControlLabel, Typography} from '@mui/material';
import Icon from '@mui/material/Icon';
import {clone} from 'lodash'

import { addNews, addPublication, getLatestPublications, updateNews } from '../utils/db_helper';
import { Link } from 'react-router-dom';


export default function ManagePublications() {
    const [pubs, setPubs] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false)
    const [form, setForm] = useState({})
    const [editingId, setEditingId] = useState(null)
    const [creating, setCreating] = useState(false)

    useEffect(() => {
        refreshList()
    }, [])

    const refreshList = () => {
        setPubs([])
        getLatestPublications(30).then((_pubs) => {
            console.log(`Got ${_pubs.length} pubs`);
            setPubs(_pubs)
        })
    }

    const handleChange = (prop, e) => {
        setForm({
            ...form,
            [prop]: e.target.value
        })
    }

    const open_dialog = () => {
        setDialogOpen(true)
        setForm({})
    }

    const dismiss_dialog = () => {
        setDialogOpen(false)
        setCreating(false)
        setEditingId(null)
    }

    const edit_post = (doc) => {
        setCreating(false)
        setEditingId(doc.id)
        setForm(clone(doc.data()))
        setDialogOpen(true)
    }

    const start_new = () => {
        setCreating(true)
        open_dialog()
        setEditingId(null)
    }

    const save = () => {
        if (creating) {
            let {link_0_type, link_0_url} = form
            form.links = [{
                type: link_0_type,
                url: link_0_url
            }]
            delete form.link_0_type
            delete form.link_0_url
            form.authors = form.authors.split(",")
            addPublication(form).then((pub) => {
                refreshList()
                setPubs(pubs.concat(pub))
                setDialogOpen(false)
            })
        }
    }

    const render_pub = (doc) => {
        let p = doc.data()
        // let delete = <IconButton iconClassName="material-icons">delete</IconButton>
        let style = {}
        const n_authors = p.authors.length;
        const secondary = `${p.publication} | ${n_authors} authors | (${p.date})`
        return (
            <ListItemButton key={p.text} style={style}>
                <ListItemText primary={p.title} secondary={secondary} />
            </ListItemButton>
        )
    }

    return (
        <div>

            <Button variant="contained" onClick={() => start_new()}>New Publication</Button>

            <Typography>For edits, use <Link to="https://console.firebase.google.com/project/jrg-v2/firestore/data/~2Fadmins~2F3l0ENtvYb7NfXDe3YuiAF9WouuU2" target="_blank">Firestore</Link></Typography>
            <Paper style={{margin: 10}}>
                <List>
                    { pubs.map(render_pub) }
                </List>
            </Paper>

            <Dialog title="Edit Post"
                    open={dialogOpen}
                    onClose={dismiss_dialog}>
                <DialogTitle>Edit Post</DialogTitle>
                <DialogContent>
                    <TextField name="title" placeholder="Title" fullWidth onChange={(e) => handleChange('title', e)} value={form.title||''} />
                    <TextField name="authors" placeholder="Authors (initial and last, comma sep)" fullWidth onChange={(e) => handleChange('authors', e)} value={form.authors||''} />
                    <TextField name="year" placeholder="Year" fullWidth onChange={(e) => handleChange('year', e)} value={form.year||''} />
                    <TextField name="date" placeholder="Date (sort, YYYY-MM-DD)" fullWidth onChange={(e) => handleChange('date', e)} value={form.date||''} />
                    <TextField name="publication" placeholder="Publication" fullWidth onChange={(e) => handleChange('publication', e)} value={form.publication||''} />
                    <TextField name="link_0_type" placeholder="First Link Type" fullWidth onChange={(e) => handleChange('link_0_type', e)} value={form.link_0_type||''} />
                    <TextField name="link_0_url" placeholder="First Link URL" fullWidth onChange={(e) => handleChange('link_0_url', e)} value={form.link_0_url||''} />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={save}>Save</Button>
                    <Button onClick={dismiss_dialog}>Cancel</Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}
