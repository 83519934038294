import React, { useEffect, useState } from 'react';
import { Paper, List, ListItemButton, ListItemText, Grid, Button } from '@mui/material';
import { getLatestLocationChanges, saveCurrentLocation } from '../utils/db_helper';
import LocationMap from './LocationMap';
import util from '../utils/util'
import { useSnackbar } from 'notistack';

import 'leaflet/dist/leaflet.css';

const N_DAYS = 30*3;

export default function ManageLocationHistory({ theme }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [changes, setChanges] = useState([])
    useEffect(() => {
        const cutoff = util.timestamp() - 60*60*24*N_DAYS
        getLatestLocationChanges(30, cutoff).then((docs) => {
            setChanges(docs)
        })
    }, [])

    const saveLocation = () => {
        util.getCurrentLocation((latitude, longitude) => {
            saveCurrentLocation(latitude, longitude).then((res) => {
                enqueueSnackbar(`Current location updated - lat=${latitude}, lon=${longitude}`)
            })
        }, (error) => {
            enqueueSnackbar("Error saving location")
        })
    }

    return (
        <div>

            <h3>Locations (last {N_DAYS} days)</h3>

            <Button onClick={saveLocation}>Save Current Location</Button>

            <Grid container>
                <Grid item xs={6}>
                    <Paper>
                        <List>
                            { changes.map((doc, i) => {
                                let loc = doc.data()
                                let text = `${loc.city}, ${loc.country}`
                                let first = i === 0;
                                if (first) text += " (latest)";
                                return (
                                    <ListItemButton key={doc.id}>
                                        <ListItemText primary={text} secondary={loc.date} />
                                    </ListItemButton>
                                )
                            })}
                        </List>
                    </Paper> 
                </Grid>
                <Grid item xs={6}>
                    <LocationMap locations={changes} />
                </Grid>
            </Grid>       

        </div>
    );
}
