import React, { useEffect, useState } from 'react';
import ThemeLink from './ThemeLink'
import util from '../utils/util';
import app from '../config/firebase';
import { collection, query, getDocs, orderBy, limit, getFirestore } from 'firebase/firestore/lite';  
import { getImageServiceUrl } from '../utils/db_helper';
import { LocationOn } from '@mui/icons-material';
import { Container, Grid } from '@mui/material';


const N_TO_FETCH = 40

export default function Photos() {
    const [photos, setPhotos] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0)
    const [loading, setLoading] = useState(false)
    const [imageUrls, setImageUrls] = useState({})

    useEffect(() => {
        fetchPhotos()
    }, [])

    const fetchPhotos = () => {
        const db = getFirestore(app);
        setLoading(true)
        const postsRef = collection(db, "my_photo");
        const q = query(postsRef, orderBy("ts_posted", "desc"), limit(N_TO_FETCH));
        getDocs(q).then((snapshot) => {
            let docs = []
            snapshot.forEach((doc) => {
                let data = doc.data()
                getImageServiceUrl(`${data.key_name}`).then((res) => {
                    imageUrls[res.key] = res.url
                    setImageUrls({...imageUrls})
                })
                docs.push(data)
            });
            setPhotos(docs)
            setLoading(false)
        });
    }

    const chooseImage = (e, i) => {
        setCurrentIndex(i)
        e.preventDefault()
        return false;
    }

    let _main, _thumbs
    if (currentIndex < photos.length) {
        let mp = photos[currentIndex]
        let src = imageUrls[mp.key_name]
        let img = <img src={src} width="100%" className="InstaMain" />
        let loc
        if (mp.location_text != null && mp.location_text.length > 0) loc = <span className="Location"><LocationOn /> {mp.location_text}</span>
        if (mp.link != null) img = <a href={mp.link} target="_blank">{img}</a>
        _main = (
            <div>
                {img}
                <h3>{ mp.caption }</h3>
                <span className="Date">{ util.printDate(mp.ts_posted * 1000) }</span>
                { loc }
            </div>
        )
    }
    if (photos.length > 0) {
        _thumbs = photos.map((mp, i) => {
            let src = imageUrls[mp.key_name]
            return <a href="#" key={i} onClick={(e) => chooseImage(e, i)}><img src={src} width="50" height="50" /></a>
        })
    } else if (loading) {
        _thumbs = []
        for (let i=0; i<20; i++) {
            _thumbs.push(<div key={i} className="animated-background photo-placeholder"></div>)
        }
    }
    return  (
        <Container maxWidth="lg" className="Photos">
            <Grid container>
                <Grid item xs={6}>
                    <div className="Thumbs">{ _thumbs }</div>
                </Grid>
                <Grid item xs={6}>
                    { _main }
                </Grid>
            </Grid>
        </Container>
        )
}
