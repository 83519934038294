import L from 'leaflet';
import { Marker, Popup, MapContainer, TileLayer } from 'react-leaflet';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25,41], 
    iconAnchor: [12,41]
});

L.Marker.prototype.options.icon = DefaultIcon;


function LocationMap({ locations }) {
    if (locations.length === 0) return null
    const lastLocation = locations.length > 0 ? locations[0].data() : null;
    let center = null;
    if (lastLocation != null) {
        center = [lastLocation.lat, lastLocation.long]
    } else {
        center = [38.9118992, -77.0109961]
    }

    return (
        <div style={styles.container}>
            <MapContainer center={center} zoom={6} scrollWheelZoom={false} style={styles.container}>
                <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                { locations.map((loc) => {
                    const locData = loc.data()
                    return (
                        <Marker key={loc.id} position={[locData.lat, locData.long]}>
                            <Popup>
                                { locData.city } on { locData.date }
                            </Popup>
                        </Marker>        
                    )
                })}
            </MapContainer>
        </div>
    )
}

const styles = {
    container: {
        height: '800px',
    }
}

export default LocationMap;