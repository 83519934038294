import React, { useEffect, useState } from 'react';
import ThemeLink from './ThemeLink';

import app from '../config/firebase';
import { getFunctions, httpsCallable } from "firebase/functions";

import { TextField, Button, Container } from '@mui/material';
import { useSnackbar } from 'notistack';

const styles = {
    tf: {
        marginBottom: 10
    }
}

export default function Contact({ theme }) {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [sending, setSending] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const changeMessage = (e) => {
        setMessage(e.currentTarget.value)
    }

    const changeEmail = (e) => {
        setEmail(e.currentTarget.value)
    }

    const send = () => {
        const functions = getFunctions()
        if (message.length > 0) {
            const sendJRGEmail = httpsCallable(functions, 'sendJRGMessage');

            // Call the Cloud Function
            setSending(true)
            sendJRGEmail({
                message: message,
                email: email
            })
            .then((result) => {
                console.log('Email sent successfully:', result.data);
                setMessage("");
                setEmail("");
                enqueueSnackbar("Message sent!")
                setSending(false);
            })
            .catch((error) => {
                console.error('Error sending email:', error);
                setSending(false);
                enqueueSnackbar("Problem sending!")
            });
        }
    }

    let disabled = message.length === 0 || sending
    return (
        <Container maxWidth="md">

            <h1>Contact</h1>
            <form method="POST" action="/message/send">
                <TextField fullWidth={true}
                    style={styles.tf}
                    name="email"
                    placeholder="Email address or a way to reach you..."
                    value={email}
                    onChange={changeEmail} />
                <TextField fullWidth={true}
                    style={styles.tf}
                    name="message"
                    placeholder="Your message..."
                    value={message}
                    multiline
                    onChange={changeMessage} />
                <Button variant="contained" onClick={() => send()} disabled={disabled}>Send</Button>
            </form>

            <div style={{marginTop: 80}}>
                <p>
                    School email: <a href="mailto:jrgordon@berkeley.edu" style={{fontFamily: "monospace", color: 'black'}}>jrgordon@berkeley.edu</a>
                    &nbsp;(<ThemeLink href="/documents/pgp.txt" theme={theme}>PGP</ThemeLink>)
                </p>
            </div>

        </Container>
    );
}
