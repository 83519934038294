import React from 'react';
import AppConstants from '../constants/AppConstants'
import ThemeLink from './ThemeLink';

export default function Footer({ theme }) {

  const SOCIAL = [
    // { icon: "facebook-square", url: "https://www.facebook.com/jeremyrgordon" },
    { icon: "linkedin", url: "http://www.linkedin.com/in/jrgordon", rel: "me" },
    { icon: "mastodon", url: "https://mastodon.social/@jgordon", rel: "me"},
    { icon: "github", url: "https://github.com/onejgordon", rel: "me"},
    { icon: "graduation-cap", url: "https://scholar.google.com/citations?hl=en&user=X2aN9twAAAAJ&view_op=list_works&sortby=pubdate", fa_type: 'fas', label: "Google Scholar"}
  ]

  var YEAR = new Date().getFullYear();
  let year_text;
  if (AppConstants.YEAR != YEAR) year_text = <span>&copy; { AppConstants.YEAR } - { YEAR } { AppConstants.SITENAME }</span>
  else year_text = <span>&copy; { YEAR } { AppConstants.SITENAME }</span>
  return (
    <div className="footer">
      <div className="Social">
      { SOCIAL.map((soc) => {
        let fa_prefix = soc.fa_type || 'fab'
        return <ThemeLink theme={theme} href={soc.url} rel={soc.rel} key={soc.url} title={soc.label} target="_blank"><i className={`${fa_prefix} fa-${soc.icon}`}></i></ThemeLink>
      }) }
      </div>
      { year_text }
    </div>
  )
}

