const AppConstants = {
    YEAR: "2016",
    SITENAME: "Jeremy Gordon",	
	TOP_LOC_TAG: "New York",
	THEMES: [
	    {'bg': "istanbul.jpg", 'caption': "Pendik (Near Istanbul), Turkey", 'color': '#94ADAD'},
	    {'bg': "bay.jpg", 'caption': "Near Angel Island, CA", 'color': '#0e7191'},
	    {'bg': "beanbags.jpg", 'caption': "Roppongi Towers, Tokyo, Japan", 'color': '#ffb346'},
	    {'bg': "tokyosign.jpg", 'caption': "Itabashi, Tokyo, Japan", 'color': '#2dc225'},
	    {'bg': "ngong.jpg", 'caption': "Ngong Hills, near Nairobi, Kenya", 'color': '#32AFF3'},
	    {'bg': "kembu.jpg", 'caption': "Near Nakuru, Kenya", 'color': '#DC9F44'},
	    {'bg': "strawberries.jpg", 'caption': "Berkeley, CA", 'color': '#C02A37'},
		{'bg': "beads.jpg", 'caption': "Bead market, Nairobi, Kenya", 'color': '#c4be08'},
		{'bg': "chairs.jpg", 'caption': "Lake Kivu, Rwanda", 'color': '#42BC30'},
		{'bg': "tokyo_train.jpg", 'caption': "Peripheral Tokyo, Japan", 'color': '#F3AB21'},
		{'bg': "forest.jpg", 'caption': "Fort Tryon Park, NYC", 'color': '#7CA7F0'},
		{'bg': "foro.jpg", 'caption': "Trajan Forum, Rome, Italy", 'color': '#F7C887'},
		{'bg': "transam.jpg", 'caption': "San Francisco, CA", 'color': '#ba8480'},
		{'bg': "denver_snow.jpg", 'caption': "Denver, CO", 'color': '#2D3C59'},
		{'bg': "caracala.jpg", 'caption': "Baths of Caracalla, Rome, Italy", 'color': '#5B2E28'},
		{'bg': "kyrgyzstan.jpg", 'caption': "Sary-Chelek, Kyrgyzstan", 'color': '#e6c109'}

    ]	
};

export default AppConstants