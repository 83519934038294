import React from 'react';
import {Link} from 'react-router-dom'


function ThemeLink({ theme, cls, rel, to, href, target, title, children }) {
    cls += " ThemeLink"
    let st = {}
    if (theme != null) st.color = theme.color
    if (to != null) return <Link to={to} className={cls} style={st}>{ children }</Link>
    else if (href != null) return <a href={href} target={target} rel={rel} title={title} className={cls} style={st}>{ children }</a>
    else return <span title={title} className={cls}>{ children }</span>
}

export default ThemeLink