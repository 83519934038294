import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBgwFKmaUzpQYtCfhldO8Lu--ndhu4vAII",
  authDomain: "jrg-v2.firebaseapp.com",
  projectId: "jrg-v2",
  storageBucket: "jrg-v2.appspot.com",
  messagingSenderId: "442612093333",
  appId: "1:442612093333:web:cdd7017fb6659e1e8ae69c"
};

const app = initializeApp(firebaseConfig);

export default app;