import AppConstants from '../constants/AppConstants'
import NavBar from './NavBar'
import Footer from './Footer'
import TopBar from './TopBar'
import util from '../utils/util'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { useNavigate, Outlet, useLocation, useOutletContext } from "react-router-dom";
import { SnackbarProvider } from 'notistack'


const muiTheme = createTheme({
  status: {

  },
});

const randomizedThemeOrder = () => {
  // Always show 0 as the default
  let theme_ids = Array.from({ length: AppConstants.THEMES.length }, (_, i) => i)  // 1 to N-1
  theme_ids = util.shuffle(theme_ids)
  return theme_ids
}

export default function Site() {
  const [orderIndex, setOrderIndex] = useState(0)
  const [themeOrder, setThemeOrder] = useState(randomizedThemeOrder())
  const [thisPage, setThisPage] = useState('')
  const location = useLocation()
  const { pathname } = location;
  let navigate = useNavigate()

  const gotoPage = (page) => {
    console.log(page)
    setThisPage(page);
    navigate(`/${page}`);
  }

  const loopTheme = () => {
    let order_index = orderIndex;
    order_index += 1
    if (order_index >= AppConstants.THEMES.length) order_index = 0
    setOrderIndex(order_index)
  }

  let theme_index = themeOrder[orderIndex]
  let theme = AppConstants.THEMES[theme_index]
  let path = pathname.replace('/','')
  let splash = ["latest", ""].indexOf(path) > -1

  return (
        <ThemeProvider theme={muiTheme}>
          <SnackbarProvider>
            <div className={splash ? "Splash" : "NonSplash"}>
              <TopBar />
              <NavBar page={thisPage} onChangePage={gotoPage} />

              <div className={splash ? "" : "container Content"}>
                <div style={{minHeight: '700px'}}>
                  <Outlet context={{theme: theme, onLoopTheme: loopTheme}} />
                </div>
              </div>

              <Footer theme={theme} />
            </div>
          </SnackbarProvider>
        </ThemeProvider>
    )
}

export function useMyTheme() {
  return useOutletContext();
}
