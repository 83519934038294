import app from '../config/firebase';
import { collection, query, getDocs, orderBy, limit, getFirestore, setDoc, doc, addDoc, where } from 'firebase/firestore/lite';  
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import util from "./util"

const FIREBASE_STORAGE_URL = "gs://jrg-v2.appspot.com/photos"

/* News posts */

const getLatestNews = (n, includeNonLive=false) => {
    const db = getFirestore(app);
    return new Promise((resolve, reject) => {
        const postsRef = collection(db, "news_post");
        let q
        if (includeNonLive) {
            q = query(postsRef, orderBy("date", "desc"), limit(n));
        } else {
            q = query(postsRef, where("live", "==", true), orderBy("date", "desc"), limit(n));
        }
        getDocs(q).then((snapshot) => {
            let docs = []
            snapshot.forEach((doc) => {
                docs.push(doc)
            });
            resolve(docs);
        });
    });
}


const updateNews = (id, post) => {
    const db = getFirestore(app);
    return new Promise((resolve, reject) => {
        const postsRef = doc(db, "news_post", id);
        setDoc(postsRef, post).then(() => {
            resolve(post);
        });
    });    
}


const addNews = (post) => {
    const db = getFirestore(app);
    return new Promise((resolve, reject) => {
        const postsRef = collection(db, "news_post");
        addDoc(postsRef, post).then(() => {
            resolve(post);
        });
    });    
}

/* Publications */

const addPublication = (pub) => {
    const db = getFirestore(app);
    return new Promise((resolve, reject) => {
        const ref = collection(db, "publication");
        addDoc(ref, pub).then(() => {
            resolve(pub);
        });
    });    
}

const getLatestPublications = (n) => {
    const db = getFirestore(app);
    return new Promise((resolve, reject) => {
        const postsRef = collection(db, "publication");
        let q = query(postsRef, orderBy("date", "desc"), limit(n));
        getDocs(q).then((snapshot) => {
            let docs = []
            snapshot.forEach((doc) => {
                docs.push(doc)
            });
            resolve(docs);
        });
    });
}

/* Locations */

const getLatestLocationChanges = (n=20, oldest_ts) => {
    const db = getFirestore(app);
    return new Promise((resolve, reject) => {
        const postsRef = collection(db, "location_change");
        const q = query(postsRef, orderBy("ts", "desc"), where("ts", ">", oldest_ts), limit(n));
        getDocs(q).then((snapshot) => {
            let docs = []
            snapshot.forEach((doc) => {
                docs.push(doc)
            });
            resolve(docs);
        });
    });
}

const saveCurrentLocation = (lat, lon) => {
    const db = getFirestore(app);
    const ts = util.timestamp();
    const data = {
        lat: lat,
        long: lon,
        ts: ts,
    };
    return setDoc(doc(db, "my_location", "latest"), data)
}

/* Storage */

const getImageServiceUrl = (key) => {
    return new Promise((resolve, reject) => {
        const storage = getStorage();
        let full_key = `${FIREBASE_STORAGE_URL}/${key}`
        getDownloadURL(ref(storage, full_key))
            .then((url) => {
                resolve({key, url});
            });
    });
}

export {
    getLatestNews,
    getLatestPublications,
    getImageServiceUrl,
    addNews,
    addPublication,
    getLatestLocationChanges,
    saveCurrentLocation,
    updateNews
}