import React, { useState } from 'react';
import {Link} from 'react-router-dom'
import AppConstants from '../constants/AppConstants';


const TopBar = () => {
  
  return (
    <div className="TopBar">
      <Link to="/"><img src="/images/jeremygordon.jpg" className="avatar img-circle img-responsive" width="50" height="50" /></Link>
      <div className="top bar"></div>
      <div className="middle bar">
        <div className="left">
          <span className="name">{ AppConstants.SITENAME }</span>
        </div>
        <div className="right">
          <span className="location">{ AppConstants.TOP_LOC_TAG }</span>
        </div>
      </div>
    </div>
  )
}


export default TopBar