import React, { useEffect, useState } from 'react';
import ThemeLink from './ThemeLink'
import { useMyTheme } from './Site';
import { CircularProgress, Container } from '@mui/material';
import { getLatestPublications } from '../utils/db_helper';

export default function Research() {
    const [pubs, setPubs] = useState([])
    const [loading, setLoading] = useState(false)

    const {theme, onLoopTheme} = useMyTheme()

    useEffect(() => {
        fetch_pubs()
    }, [])

    const fetch_pubs = () => {
        getLatestPublications(30).then((docs) => {
            setPubs(docs)
            setLoading(false)
        })
        setLoading(true)
    }

    const render_pub = (doc) => {
        let pub = doc.data();
        let authors = []
        pub.authors.forEach((author, i) => {
            let cls = "Author"
            if (author.startsWith("JR Gordon")) cls += " Me"
            authors.push(<span itemProp="author" className={cls} key={i}>{ author }</span>)
            let last = i == pub.authors.length - 1
            if (!last) authors.push(<span key={`comma${i}`}>, </span>)
        })
        let no_links = pub.links == null || pub.links.length == 0
        let link_cls = no_links ? "Title no_links" : "Title"
        let main_url
        if (pub.no_main_link) {
            link_cls = "Title no_links"
        } else {
            main_url = no_links ? null : pub.links[0].url
        }
        let rendered_links
        if (!no_links) rendered_links = (
            <div className="ContentLinks">
                { pub.links.map((link, i) => <a href={link.url} key={i} itemProp="url" target="_blank">{ link.type }</a>)}
            </div>
        )
        return (
            <div itemScope itemType="http://schema.org/ScholarlyArticle" className="Pub" key={pub.title}>
                <ThemeLink theme={theme} href={main_url} target="_blank" itemProp="name" cls={link_cls}>{ pub.title }</ThemeLink>
                <div itemProp="author" className="Authors">
                    { authors }
                </div>
                <div className="Publication" itemProp="isPartOf" itemScope itemType="http://schema.org/PublicationIssue" itemID="#issue">
                    <span itemScope itemType="http://schema.org/Periodical" itemID="#periodical">
                    <span itemProp="name">{ pub.publication }</span>,
                    </span>
                    <span itemProp="datePublished" className="Year">{ pub.year }</span>
                </div>
                { rendered_links }
            </div>
            )
    }

    return (
        <Container maxWidth="lg">

            <div>
                <p className="lead">
                    I completed my PhD at <ThemeLink href="https://www.berkeley.edu" target="_blank" theme={theme}>UC Berkeley</ThemeLink> at the <ThemeLink href="https://www.ischool.berkeley.edu/people/jeremy-gordon" target="_blank" theme={theme}>School of Information</ThemeLink>. I was advised by <ThemeLink href="https://people.ischool.berkeley.edu/~chuang/" target="_blank" theme={theme}>John Chuang</ThemeLink> and collaborated with researchers at the <ThemeLink href="http://biosense.berkeley.edu/" target="_blank" theme={theme}>BioSENSE Lab</ThemeLink>, as well as Giovanni Pezzulo's <ThemeLink href="https://www.istc.cnr.it/en/group/conan-0" target="_blank" theme={theme}>Cognition in Action (CONAN) lab</ThemeLink>.
                </p>

                <h2>Research Statement</h2>

                <p className="lead">
                Humans may be alone in the extent to which we can imagine scenarios we've never experienced and evaluate potential futures before they come to be. This capacity for prospection plays an integral role in problem-solving and planning. Forward models that generate rollouts through state space feature prominently in reinforcement learning (e.g. Monte Carlo methods of value function estimation), but typically lack biological plausibility, and are often computationally intractable for realistic problems.
                </p>

                <p className="lead">
                Building on active inference (which sees cognition as a dynamical, surprise minimizing system) I seek to understand how recruitment of the body enables us to not only learn, but learn from, an internal generative model. Specifically, I'm interested in an important open question in cognitive science and artificial intelligence: how are the contours of foresight shaped by embodied interaction? Relatedly, how might the results of imagined trajectories be compressed into useful representations? What techniques help us interrogate our world model to balance epistemic and homeostatic demands? I pursue these questions through two interlinked methodological lines: the development of a novel computational model of prospective mental simulation, and the implementation of behavioral experiments to guide and test its predictions. My planning and forecasting experiments are implemented in VR to enable the capture of fine-grained motion, gaze, and other physiological signals often correlated with latent model-based processes.
                </p>

                <p className="lead">
                Applications of this work may extend into a variety of critical domains. Computational agents leveraging biologically inspired simulation dynamics may be more effective collaborators, able to communicate legible beliefs, risks, and hypothesized futures. Additionally, by better understanding how individuals reason about the future, we might confront our own limitations in preparing for long-term out-of-distribution phenomena such as climate change.
                </p>

                <br/><br/>

                <h2>Publications</h2>

                <div className="Publications">

                    { pubs.map((p) => render_pub(p)) }
                    { loading ? <CircularProgress /> : null }

                </div>

            </div>

        </Container>
    );
}
