import React from 'react';
import ThemeLink from './ThemeLink'
import NewsTimeline from './NewsTimeline'
import AppConstants from '../constants/AppConstants'
import { useMyTheme } from './Site';
import { Container } from '@mui/material';


export default function Latest() {

    const {theme, onLoopTheme} = useMyTheme()

    const loopTheme = (e) => {
        onLoopTheme()
        e.preventDefault()
        return false;
    }

    let st = {}
    st.backgroundImage = `url(images/bgs/${theme.bg}`
    let caption = theme.caption
    let blurb_st = {
        borderBottom: `4px solid ${theme.color}`
    }
    return (
        <div>
            <div className="Latest" style={st}>
                <div className="Blurb" style={blurb_st}>
                    <span className="time-label">Currently</span>
                    <p className="lead-big">
                        I'm building products in the Home Health space at Olli (TS S24). 
                    </p>
                    <span className="time-label">Previously</span>
                    <p>
                    Most recently, I <ThemeLink href="https://www.ischool.berkeley.edu/people/jeremy-gordon" target="_blank" theme={theme}>completed my PhD</ThemeLink> at <ThemeLink href="https://www.ischool.berkeley.edu" target="_blank" theme={theme}>Berkeley's School of Information</ThemeLink> where I studied <ThemeLink to="/research" theme={theme}>mental simulation and embodied, prospective cognition</ThemeLink>.
                    </p>
                    <p>
                    Before that, I studied engineering at Stanford, was a UI designer at a startup in Tokyo, and a fellow with <ThemeLink theme={theme} href="http://www.kiva.org">Kiva.org</ThemeLink> in Kenya. I co-founded <ThemeLink theme={theme}  href="https://www.echomobile.io" target="_blank">Echo Mobile</ThemeLink>, an SMS/voice-based survey &amp; engagement platform based in Nairobi.
                    </p>
                </div>
                <div className="Caption"><i className="fas fa-map-pin"></i> { caption } &middot; <a href="#" onClick={(e) => loopTheme(e)}><i className="fas fa-sync"></i></a></div>
            </div>

            <div className="clearfix"></div>

            <div className="container">
                <Container maxWidth="md">
                    <h3>Some recent things</h3>

                    <NewsTimeline theme={theme} />
                </Container>
            </div>

        </div>
    )
}

Latest.defaultProps = {
    theme: AppConstants.THEMES[0]
}