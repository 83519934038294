import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';

const STYLES = {
  root: {
    justifyContent: 'space-around',
  },
  gridList: {
    width: '100%'
  },
  icon: {
    color: "#FFF"
  }
};

const PROJECTS = {
  "dissertation": {
    title: "Embodying the Future: Modeling Visually Guided Planning as Prospective Mental Simulation",
    description: "Supplementary media and materials for this dissertation can be downloaded from the OSF project page linked below.",
    links: [
      {
        label: "Dissertation PDF",
        url: "/documents/JGordon Dissertation Embodying the Future 2023.pdf",
        type: "pdf"
      },
      {
        label: "OSF",
        url: "https://osf.io/req2c/",
        type: "materials"
      },
    ]
  },    
  "planning_together": {
    title: "Strategic task decomposition in joint action",
    description: "Supporting video for this work shared below.",
    links: [
      {
        label: "Sample screencap from joint task",
        url: "https://jrg-media.s3.amazonaws.com/planning-together/Planning+together+screen+recording.mov",
        type: "video",
      }
    ]
  },
  "pednav_preplay": {
    title: "Hearing the Way Forward: Exploring Ambient Navigational Awareness with Reduced Cognitive Load through Spatial Audio-AR",
    description: "Various supporting media and videos shared below.",
    links: [
      {
        label: "Tutorial video (common)",
        url: "https://jrg-media.s3.amazonaws.com/pednav-preplay/TutorialCommonVideo_v5.mp4",
        type: "video",
      },
      {
        label: "Tutorial video (isolated audio)",
        url: "https://jrg-media.s3.amazonaws.com/pednav-preplay/TutorialStaticVideo_v5.mp4",
        type: "video",
      },
      {
        label: "Tutorial video (simulated audio-visual)",
        url: "https://jrg-media.s3.amazonaws.com/pednav-preplay/TutorialInteractiveVideo_v5.mp4",
        type: "video",
      }
    ]
  },
  "adp": {
      title: "Active Dynamical Prospection: Modeling Mental Simulation as Particle Filtering for Sensorimotor Control during Pathfinding",
      description: "Various supporting media and videos shared below.",
      links: [
        // {
        //   label: "Preprint",
        //   url: "https://arxiv.org/abs/2103.07966",
        //   type: "banner",
        // },
        {
          label: "Paper (MIT Press)",
          url: "https://direct.mit.edu/isal/proceedings/isal/33/85/102976",
          type: "banner"
        },
        {
          label: "Code",
          url: "https://github.com/onejgordon/active-dynamical-prospection",
          type: "banner",
        },
        {
          label: "Sample Human Trial Screencapture",
          url: "https://jrg-media.s3.amazonaws.com/EPPSampleTrialScreenRecording.mp4",
          type: "video"
        },
        {
          label: "Map 1 - All human participants",
          url: "https://jrg-media.s3.amazonaws.com/map_1_anim_81_subjects_fr_200.mp4",
          type: "video",
        },
        {
          label: "Map 2 - All human participants",
          url: "https://jrg-media.s3.amazonaws.com/map_2_anim_81_subjects_fr_200.mp4",
          type: "video"
        },
        {
          label: "Map 3 - All human participants",
          url: "https://jrg-media.s3.amazonaws.com/map_3_anim_81_subjects_fr_200.mp4",
          type: "video"
        },
        {
          label: "Map 4 - All human participants",
          url: "https://jrg-media.s3.amazonaws.com/map_4_anim_81_subjects_fr_200.mp4",
          type: "video"
        },
        {
          label: "Map 5 - All human participants",
          url: "https://jrg-media.s3.amazonaws.com/map_5_anim_81_subjects_fr_200.mp4",
          type: "video"
        },
        {
          label: "Map 5 - All human participants",
          url: "https://jrg-media.s3.amazonaws.com/map_6_anim_81_subjects_fr_200.mp4",
          type: "video"
        },
        {
          label: "Map 6 - All human participants",
          url: "https://jrg-media.s3.amazonaws.com/map_7_anim_81_subjects_fr_200.mp4",
          type: "video"
        },
        {
          label: "Map 7 - All human participants",
          url: "https://jrg-media.s3.amazonaws.com/map_8_anim_81_subjects_fr_200.mp4",
          type: "video"
        },
        {
          label: "Map 8 - All human participants",
          url: "https://jrg-media.s3.amazonaws.com/map_9_anim_81_subjects_fr_200.mp4",
          type: "video"
        },
        {
          label: "Map 9 - All human participants",
          url: "https://jrg-media.s3.amazonaws.com/map_10_anim_81_subjects_fr_200.mp4",
          type: "video"
        },
        {
          label: "Map 10 - All human participants",
          url: "https://jrg-media.s3.amazonaws.com/map_11_anim_81_subjects_fr_200.mp4",
          type: "video"
        },
        {
          label: "Map 11 - All human participants",
          url: "https://jrg-media.s3.amazonaws.com/map_1_anim_81_subjects_fr_200.mp4",
          type: "video"
        },
        {
          label: "Map 1 - Agent sim",
          url: "https://jrg-media.s3.amazonaws.com/map_1_agent_sim.mp4",
          type: "video"
        },
        {
          label: "Map 2 - Agent sim",
          url: "https://jrg-media.s3.amazonaws.com/map_2_agent_sim.mp4",
          type: "video"
        },
        {
          label: "Map 3 - Agent sim",
          url: "https://jrg-media.s3.amazonaws.com/map_3_agent_sim.mp4",
          type: "video"
        },
        {
          label: "Map 4 - Agent sim",
          url: "https://jrg-media.s3.amazonaws.com/map_4_agent_sim.mp4",
          type: "video"
        },
        {
          label: "Map 5 - Agent sim",
          url: "https://jrg-media.s3.amazonaws.com/map_5_agent_sim.mp4",
          type: "video"
        },
        {
          label: "Map 6 - Agent sim",
          url: "https://jrg-media.s3.amazonaws.com/map_6_agent_sim.mp4",
          type: "video"
        },
        {
          label: "Map 7 - Agent sim",
          url: "https://jrg-media.s3.amazonaws.com/map_7_agent_sim.mp4",
          type: "video"
        },
        {
          label: "Map 8 - Agent sim",
          url: "https://jrg-media.s3.amazonaws.com/map_8_agent_sim.mp4",
          type: "video"
        },
        {
          label: "Map 9 - Agent sim",
          url: "https://jrg-media.s3.amazonaws.com/map_9_agent_sim.mp4",
          type: "video"
        },
        {
          label: "Map 10 - Agent sim",
          url: "https://jrg-media.s3.amazonaws.com/map_10_agent_sim.mp4",
          type: "video"
        },
        {
          label: "Map 11 - Agent sim",
          url: "https://jrg-media.s3.amazonaws.com/map_11_agent_sim.mp4",
          type: "video"
        },
      ]
    },
}

export default function ProjectDetail() {
    const {project_id} = useParams();

    const render_link = (link, key) => {
      if (link.type == "video") {
        return (
          <div key={key}>
            <h3>{ link.label }</h3>
            <video width="800" height="600" controls preload="none">
              <source src={link.url} />
               Your browser does not support the video tag.
            </video>
          </div>
        )
      } else {
        return <li><a href={ link.url } target="_blank">{ link.label }</a></li>
      }
    }

    const render_banner_link = (link, i) => {
      return <a className="large" key={i} href={ link.url } target="_blank">{ link.label }</a>
    }

    let proj = PROJECTS[project_id]
    if (proj == null) return <div></div>
    let banner_links = proj.links.filter((x) => x.type == 'banner')
    let other_links = proj.links.filter((x) => x.type != 'banner')
    return (
        <Container maxWidth="md">
          <h1>{ proj.title }</h1>
          <p className="lead">{ proj.description }</p>
          <div className="ContentLinks">
            { banner_links.map(render_banner_link) }
          </div>
          <ul>
            { other_links.map(render_link) }
          </ul>
        </Container>
    );
}
