import React, { useEffect, useState } from 'react';
import {Paper, List, ListItem, Dialog, IconButton, TextField,
    Button, Toggle, Switch, ListItemButton, ListItemIcon, ListItemText, DialogTitle, DialogContent, DialogActions, FormControlLabel, Container, Select, MenuItem} from '@mui/material';

import app from '../config/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth, signOut } from 'firebase/auth';

import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom';


const auth = getAuth(app);

function NavMenu() {
    const navigate = useNavigate()

    const logout = () => {
        signOut(auth).then(() => {
            navigate("/")
        });
    }

    return (
        <div>
            <NavLink to="news">News Posts</NavLink> &middot;
            <NavLink to="publications">Publications</NavLink> &middot;
            <NavLink to="locations">Location Changes</NavLink> &middot;
            <Button onClick={logout} size="sm">Log out</Button>
        </div>
    )
}

export default function Admin() {
    const [user, loading, error] = useAuthState(auth);

    const signedIn = user != null;
    const title = signedIn ? `Admin (${user.email})` : "Admin (signed out)"

    return (
        <Container maxWidth="lg">
            <div className="Admin">
                <h1>{ title }</h1>
                
                { signedIn ? <NavMenu /> : <Link to="/login">Login</Link> }

                <Outlet user={user} />
                
            </div>

        </Container>
    )
}
