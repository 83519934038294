import React, { useEffect, useState } from 'react';
import ThemeLink from './ThemeLink';
import { getFunctions, httpsCallable } from "firebase/functions";
import { TextField, Button, Container } from '@mui/material';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';


const styles = {
    tf: {
        marginBottom: 10
    }
}

export default function Login({ theme }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [signingIn, setSigningIn] = useState(false);

    const navigate = useNavigate();


    const changePassword = (e) => {
        setPassword(e.currentTarget.value)
    }

    const changeEmail = (e) => {
        setEmail(e.currentTarget.value)
    }

    const login = () => {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            console.log(user)
            navigate("/admin/news");

        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorMessage)
            alert(errorMessage);
        });
        
    }

    return (
        <Container maxWidth="md">

            <h1>Login</h1>

            <TextField fullWidth={true}
                style={styles.tf}
                name="email"
                placeholder="Email address"
                value={email}
                onChange={changeEmail} />
            <TextField fullWidth={true}
                style={styles.tf}
                name="password"
                type="password"
                placeholder="Password"
                value={password}
                onChange={changePassword} />
            <Button variant="contained" onClick={() => login()} disabled={signingIn}>Sign In</Button>

        </Container>
    );
}
